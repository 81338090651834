const getBuildEnvironment = () => {
  const {
    DEV,
    PROD,
    MODE,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_SCOPE,
    VITE_ENABLE_PRODUCTION_PROFILING,
    VITE_API_SCANDEVICE,
    VITE_API_SCANDEVICE_V3,
    VITE_DATADOG_URL,
    VITE_MAPBOX_API_TOKEN,
    VITE_GRAFANA_SCANS_DASHBOARD_URL,
  } = import.meta.env;

  return {
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    auth0Scope: VITE_AUTH0_SCOPE,
    apiUrl: VITE_API_SCANDEVICE,
    apiUrlV3: VITE_API_SCANDEVICE_V3,
    datadogUrl: VITE_DATADOG_URL,
    enableProductionProfiling: VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    isDev: DEV,
    isProd: PROD,
    isStaging: MODE === 'test',
    mapboxApiToken: VITE_MAPBOX_API_TOKEN,
    grafanaScansDashboardUrl: VITE_GRAFANA_SCANS_DASHBOARD_URL,
  };
};

const getRuntimeEnvironment = () => {
  const env = window.__env__ || {};

  const envVars = {
    auth0Audience: env.VITE_AUTH0_AUDIENCE,
    auth0ClientId: env.VITE_AUTH0_CLIENT_ID,
    auth0Domain: env.VITE_AUTH0_DOMAIN,
    auth0Scope: env.VITE_AUTH0_SCOPE,
    apiUrl: env.VITE_API_SCANDEVICE,
    apiUrlV3: env.VITE_API_SCANDEVICE_V3,
    datadogUrl: env.VITE_DATADOG_URL,
    enableProductionProfiling: env.VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    isDev: env.DEV,
    isProd: env?.PROD,
    isStaging: env?.MODE === 'test',
    mapboxApiToken: env.VITE_MAPBOX_API_TOKEN,
    grafanaScansDashboardUrl: env.VITE_GRAFANA_SCANS_DASHBOARD_URL,
  };

  // Filter out entries with undefined values
  const definedVars = Object.fromEntries(
    Object.entries(envVars).filter(([, value]) => value !== undefined),
  );

  return definedVars;
};

export const useEnvironment = () => {
  const env = {
    ...getBuildEnvironment(),
    ...getRuntimeEnvironment(),
  };

  return env;
};
