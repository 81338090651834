import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Box, Button, ButtonGroup, useTheme } from '@mui/material';
import * as React from 'react';

import { useEnvironment } from '@hooks/useEnvironment';

export interface LastKnownLocationMapProps {
  lat?: number | null;
  long?: number | null;
  initialZoomLevel?: number;
}

export const LastKnownLocationMap: React.FC<LastKnownLocationMapProps> = ({
  lat,
  long,
  initialZoomLevel = 16,
}) => {
  const [hasError, setHasError] = React.useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(initialZoomLevel);
  const { mapboxApiToken } = useEnvironment();
  const theme = useTheme();

  if (!lat || !long) {
    return null;
  }

  const width = theme.spacing(60);
  const height = theme.spacing(40);
  const urlHeight = height.replace('px', '');
  const urlWidth = width.replace('px', '');
  const mapboxBaseApiUrl = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static';
  const imgSrc = `${mapboxBaseApiUrl}/pin-l+45B9A3(${long},${lat})/${long},${lat},${zoomLevel}/${urlWidth}x${urlHeight}?access_token=${mapboxApiToken}&logo=false`;

  return (
    <Box
      alignItems="center"
      border={hasError ? 1 : undefined}
      display="flex"
      height={height}
      justifyContent={hasError ? 'center' : 'flex-start'}
      marginY={1}
      position="relative"
      width={width}
    >
      {!hasError ? (
        <>
          <img
            alt="Map of the last known location"
            onError={() => setHasError(true)}
            src={imgSrc}
          />
          <ButtonGroup
            color="secondary"
            orientation="vertical"
            size="small"
            sx={(theme) => ({
              position: 'absolute',
              bottom: theme.spacing(1),
              right: theme.spacing(1),
            })}
            variant="contained"
          >
            <Button
              aria-label="Zoom in of last known location map"
              onClick={() => setZoomLevel(zoomLevel + 1)}
            >
              <AddOutlinedIcon fontSize="inherit" />
            </Button>
            <Button
              aria-label="Zoom out of last known location map"
              onClick={() => setZoomLevel(zoomLevel - 1)}
            >
              <RemoveOutlinedIcon fontSize="inherit" />
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <div>Map could not be loaded</div>
      )}
    </Box>
  );
};
